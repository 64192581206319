var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "SKILL_LEVEL_EDITOR" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { cancel: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  !_vm.isReadOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.modalOk()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("skill.auto_scheduling_detail.line1"))),
          ]),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("skill.auto_scheduling_detail.line2"))),
          ]),
          _vm._l(_vm.levels, function (item, index) {
            return [
              _c(
                "b-form-group",
                {
                  key: index,
                  attrs: {
                    label: _vm.$t("skill.field.skillTypeLevel", [item.kind]),
                    "label-for": `sLevel_${index}`,
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: `sLevel_${index}`,
                          type: "number",
                          max: 365,
                          min: 1,
                          readonly: _vm.isReadOnly,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.onLevelBlur(index)
                          },
                        },
                        model: {
                          value: item.data,
                          callback: function ($$v) {
                            _vm.$set(item, "data", $$v)
                          },
                          expression: "item.data",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }