<template>
  <div id="WEBSITES_FORM" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      @hidden="hidden"
      @ok="ok"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-form-group>
        <label class="mr-1">{{ $t(`customer.field.websites`) }}</label>
        <b-row v-for="(website, index) in websites" v-bind:key="index">
          <b-col lg>
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" :id="`website${index}`" v-model="websites[index]" trim></b-form-input>
                <b-input-group-append>
                  <b-button :id="`WEBSITE_BTN_ADD_${index}`" variant="secondary" @click="fieldItemAdd(index, null)" ><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                  <b-popover boundary="window" :target="`WEBSITE_BTN_ADD_${index}`" triggers="hover" placement="top">
                    {{ $t('button.add') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button :id="`WEBSITE_BTN_REMOVE_${index}`" variant="secondary" @click="fieldItemRemove(index, null)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                  <b-popover boundary="window" :target="`WEBSITE_BTN_REMOVE_${index}`" triggers="hover" placement="top">
                    {{ $t('button.remove') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="canEdit()">
          <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { objectClone } from '@/helpers'

export default {
  name: 'WebsitesModal',
  components: {
    
  },
  props: {
    title:     { type: String,   default: null }
    , readOnly:  { type: Boolean,  default: false }
    , show:      { type: Boolean, required: true }
    , permissionName: { type: String, required: true }
    , websiteList: { type: Array, default: () => [] }
  },
  data() {
    return {
      editable: false
      , modalShow: false
      , websites: []
      
    }
  },
  created() {
    this.prepareValue(this.websiteList)
  },
  mounted() {
    this.modalShow = this.show
  },
  beforeDestroy() {
  },
  computed: {
    isReadOnly() {
      return !this.editable && this.readOnly
    }
    , labelTitle() {
      return this.title? this.title: this.$t('badge.editWebsites')
    }
   
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue
      }
      if (newValue) {
        this.prepareValue(this.websiteList)
      }
    }
  },
  methods: {
    ok() {
      // don't send null values for websites and socials
      if (this.websites.length > 0 && this.websites[0] === null || this.websites[0].trim().length == 0) {
        this.websites.splice(0, this.websites.length)
      }

      this.$emit('ok', { websiteList: objectClone(this.websites) })
    },
    prepareValue(newValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        this.websites.splice(0, this.websites.length, ...newValue)
      } else {
        this.websites.splice(0, this.websites.length, null)
      }
    },
    fieldItemAdd(index, _default) {
      this.websites.splice(index+1, 0, _default)
    },
    fieldItemRemove(index, _default) {
      this.websites.splice(index, 1)
      if (this.websites.length == 0) {
        this.websites.push(_default)
      }
    },
    hidden() {
      this.$emit('cancel')
      this.$emit('update:show', false)
    }
  }
}
</script>
<style>
</style>